import { getCommonParamsOptions } from '@/utils/common.js'
export const baseViewFormConfig = {
  formItems: [
    {
      field: 'rid',
      type: 'input',
      label: 'param.rid',
      otherOptions: {
        disabled: true
      }
    },
    {
      field: 'index',
      type: 'input',
      label: 'general.index',
      otherOptions: {
        disabled: true
      }
    },

    {
      field: 'exponent',
      type: 'input',
      label: 'Exponent',
      otherOptions: {
        disabled: true
      }
    },

    {
      field: 'expDate',
      type: 'datepicker',
      label: 'Expiry Date',
      otherOptions: {
        format: 'YYMMDD',
        valueFormat: 'YYMMDD',
        type: 'date',
        disabled: true
      }
    },
    {
      field: 'checkSum',
      type: 'input',
      label: 'Check Sum',
      otherOptions: {
        disabled: true
      }
    },
    {
      field: 'status',
      type: 'select',
      label: 'general.status',
      isResolveGlobalParams: true,
      options: [],
      handler: () => getCommonParamsOptions('status'),
      otherOptions: {
        disabled: true
      }
    },
    {
      field: 'tenantId',
      type: 'input',
      label: 'user.tenant',
      otherOptions: {
        disabled: true
      }
    },
    {
      field: 'modul',
      type: 'textarea',
      label: 'Modulus',
      otherOptions: {
        showWordLimit: true,
        maxlength: 512,
        disabled: true
      },
      colLayout: {
        xl: 24,
        lg: 24,
        md: 24,
        sm: 24,
        xs: 24
      }
    },
    {
      field: 'description',
      type: 'textarea',
      label: 'general.description',

      otherOptions: {
        maxlength: 500,
        disabled: true
      },
      colLayout: {
        xl: 24,
        lg: 24,
        md: 24,
        sm: 24,
        xs: 24
      }
    }
  ]
}
